<template>
  <div class="flex overflow-hidden">
    <div class="py-8 pr-7 w-full">
      <div class="flex justify-between items-center">
        <h1 class="text-2xl font-bold">Inventory List</h1>
        <button
          @click="overlay = true"
          class="bg-primary py-4 px-7 rounded-xl shadow-lg flex items-center font-bold text-white"
        >
          <plus class="mr-5" />
          Add New Item
        </button>
      </div>
      <div class="" v-if="items.length !== 0">
        <inventory-table
          :allItems="items"
          :perPage="perPage"
          :total="total"
          :currentPage="currentPage"
          @fetchPage="fetchInventory"
          @delete="deleteInventory"
          @edit="editInventory"
        />
      </div>
      <div class="h-full w-full flex items-center justify-center" v-else>
        <Spinner />
      </div>
    </div>
    <add-new-inventory
      v-if="overlay"
      @close="closeOverlay()"
      @created="
        overlay = false;
        fetchInventory();
      "
      :inventory="inventory"
      :edit="edit"
    />
  </div>
</template>

<script>
import { fetchInventories, deleteInventoryItem } from "@/services/inventory";

export default {
  name: "Inventory",
  components: {
    plus: () => import("@/assets/icons/PlusIcon.vue"),
    AddNewInventory: () =>
      import("@/components/Inventory/AddNewInventoryModal.vue"),
    InventoryTable: () => import("@/components/Inventory/InventoryTable.vue"),
    Spinner: () => import("@/components/Base/Spinner.vue"),
  },
  data() {
    return {
      inventory: {
        number_of_items: 0,
        price_per_items: 0,
        inventory_type_id: 0,
        inventory_item_id: 0,
        totalprice_of_items: 0,
        notify_when_lower: 0,
      },
      edit: false,
      overlay: false,
      items: [],
      perPage: 0,
      currentPage: 0,
      total: 0,
    };
  },
  methods: {
    async fetchInventory(page = 1) {
      this.edit = false;
      try {
        const inventories = await fetchInventories(page);
        this.items = inventories.data.inventory.data;
        this.currentPage = inventories.data.inventory.current_page;
        this.perPage = inventories.data.inventory.per_page;
        this.total = inventories.data.inventory.total;
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
    },
    async deleteInventory(item_id) {
      try {
        await deleteInventoryItem(item_id);
        this.$toast.success("Invetory Type deleted");
        this.fetchInventory();
      } catch (error) {
        console.log(error);
        this.$toast.error(error.response.data.message || error);
      }
    },
    editInventory(item) {
      this.inventory = {
        number_of_items: item.number_of_items,
        price_per_items: item.price_per_items,
        inventory_type_id: item.inventory_type_id,
        inventory_item_id: item.inventory_item_id,
        totalprice_of_items: item.totalprice_of_items,
        notify_when_lower: item.notify_when_lower,
        id: item.id,
        name: item.inventory_type.inventory_types_name,
      };
      this.edit = true;
      this.overlay = true;
    },
    closeOverlay() {
      this.edit = false;
      this.overlay = false;
      this.inventory = {
        number_of_items: 0,
        price_per_items: 0,
        inventory_type_id: 0,
        inventory_item_id: 0,
        totalprice_of_items: 0,
        notify_when_lower: 0,
      };
    },
  },
  mounted() {
    this.fetchInventory();
  },
};
</script>
